import * as React from "react"
import Layout from '../components/layout'
import Member from "../components/member"

export default function Example() {
    return <Layout>
        <Member
            pb="2"
            subheading="نبذة عن الشركة:"
            subheadingProps={{
                borderBottom: '4px solid',
                borderColor: "secondary",
                w: '150'
            }}
            text="أُنشئت شركة أبراج للتنمية والإستثمار المساهمة الخصوصية المحدودة في عام 2008، وسجلت لدى مراقب الشركات وفقاً لأحكام قانون الشركات تحت رقم (562522078). والغرض الأساسي للشركة كما حدده عقد تأسيسها هو  بناء المنشآت الكاملة أو جزء منها، والقيام بالأنشطة العقارية بكافة أنواعها.
            يبلغ رأس مال شركة أبراج (25,000,000) خمسة وعشرون مليون دولار أمريكي موزعة على (25,000,000) خمسة وعشرون مليون سهم بقيمة اسمية دولار امريكي للسهم الواحد. وتمارس الشركة نشاطها في مقرها الكائن في مبنى أبراج هاوس والواقع في منطقة عين منجد بمدينة رام الله."
            imagesTitle="مجلس إدارة شركة أبراج للتنمية والاستثمار"
            images={[{
                src: '/images/member-1.png'
            }, {
                src: '/images/member-2.png'
            }]}
        />

        <Member 
            py="4"
            subheading="أهداف الشركة:"
            subheadingProps={{
                borderBottom: '4px solid',
                borderColor: "secondary",
                w: '150'
            }}
            text="تهدف شركة أبراج لإدخال نمط جديد من المشاريع العقارية الحديثة التي تنعكس نتائجها بصورة إيجابية على المستوى المعيشي للمجتمع والأفراد والنهوض بالواقع العقاري وتطويره عن طريق:
            إنشاء مناطق سياحية وصناعية متقدمة.
بناء المجمعات التجارية، الإدارية، السكنية والضواحي.
بناء مراكز التسوق الحديثة والمراكز التجارية.
المشاركة بنشاط وفاعلية في تنمية الأراضي التجارية من خلال شراء الأراضي في المناطق الحيوية، وتوفير البنية التحتية اللازمة، وتقسيم وإفراز الأراضي وبيعها لبناء الوحدات السكنية.
"
            images={[{
                src: '/images/member-3.png'
            }, {
                src: '/images/member-4.png'
            }]}
        />

        <Member 
            py="4"
            subheading="رسالة الشركة:"
            subheadingProps={{
                borderBottom: '4px solid',
                borderColor: "secondary",
                w: '150'
            }}
            text="تكمن مهمة شركة أبراج في المساهمة بشكل فاعل بوضع أسس قوية ومتينة لسوق العقارات والاستثمارات الفلسطيني، لتكون ركناً أساسيا في تحريك عجلة التنمية المستدامة، مما يدعم تطوير البنية التحتية للقطاع العقاري وبالتالي تحسين الوضع الإقتصادي الفلسطيني."
            images={[{
                src: '/images/member-5.png'
            }]}
        />

        <Member 
            py="4"
            subheading="رؤية الشركة:"
            subheadingProps={{
                borderBottom: '4px solid',
                borderColor: "secondary",
                w: '150'
            }}
            text="تسعى شركة أبراج لإدخال أفكار جديدة وإتجاهات هندسية حديثة، من شأنها أن تتضفي رونقاً مميزاً للأذواق المختلفة بالإضافة إلى الإبتكار بما يتفق مع مبادئ الإستثمار وذلك من خلال إستخدام الأدوات الحديثة مدعمةً بالإبداع للوصول لغاية تحقيق عوائد مالية مجدية، ودعم مسيرة التنمية الإقتصادية، والمساهمة في تحسين البيئة الاستثمارية."
        />
    </Layout>
}